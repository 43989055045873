// @flow

import React from "react";
import { Link } from "gatsby";

import useGroups from "../hooks/use-groups";
import useLessons from "../hooks/use-lessons";

import Header from "../components/Header";
import Layout from "../layouts/layout";

function Lessons() {
  const lessons = useLessons()
    .sort((a, b) => (a.data.number > b.data.number ? 1 : -1))
    .map(lesson => (
      <li key={lesson.data.number}>
        <Link to={`/edukacja/${lesson.slug}`}>
          {lesson.data.number}. {lesson.data.title}
        </Link>
      </li>
    ));

  const groups = useGroups()
    .sort((a, b) => (a.slug > b.slug ? 1 : -1))
    .map(group => (
      <li key={group.slug}>
        <Link to={`/zespoly/${group.slug}`}>{group.name}</Link>
      </li>
    ));

  const other = [
    { name: "Deklaracja dostępności", slug: "deklaracja-dostepnosci" },
    { name: "Edukacja", slug: "edukacja" },
    { name: "Licencje", slug: "licencje" },
    { name: "Kontakt", slug: "kontakt" },
    { name: "O projekcie", slug: "o-projekcie" },
    { name: "Polityka prywatności", slug: "polityka-prywatnosci" },
    { name: "Zespoły", slug: "zespoly" }
  ].map(link => (
    <li key={link.slug}>
      <Link to={`/${link.slug}/`}>{link.name}</Link>
    </li>
  ));

  return (
    <Layout title="Mapa strony">
      <main>
        <Header>Mapa strony</Header>
        {other}
        <h2>Zespoły</h2>
        <ul>{groups}</ul>
        <h2>Lekcje</h2>
        <ul>{lessons}</ul>
      </main>
    </Layout>
  );
}

export default Lessons;
