// @flow

import React from "react";
import styled from "@emotion/styled";

const HeaderStyled = styled("h1")`
  font-size: 24px;
  line-height: 33px;
  font-weight: bold;
  border-bottom: 2px solid black;
  padding-bottom: 10px;
`;

type Props = {
  children: Element | string,
  id: ?string
};

function Header(props: Props) {
  const { children, id } = props;

  return (
    <header>
      <HeaderStyled id={id}>{children}</HeaderStyled>
    </header>
  );
}

export default Header;
