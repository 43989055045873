import { graphql, useStaticQuery } from "gatsby";

const useGroups = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        filter: { fileAbsolutePath: { regex: "/groups.*info.mdx$/i" } }
        sort: { fields: frontmatter___title, order: ASC }
      ) {
        nodes {
          fileAbsolutePath
          frontmatter {
            name
            image {
              sharp: childImageSharp {
                fluid(maxWidth: 300, maxHeight: 220) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `);

  return data.allMdx.nodes.map(post => ({
    name: post.frontmatter.name,
    slug: post.fileAbsolutePath
      .split("/")
      .splice(-2, 1)
      .join(),
    image: post.frontmatter.image
  }));
};

export default useGroups;
